.faq-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding-top: 3rem;
  color: #3E4852;
  text-align: left;
  padding-left: 1.3rem;
  margin-bottom: 3rem;
}

.faq-question {
  background-color: white;
  border: 1.5px solid #e2e2e2;
  /* font-weight: bold; */
  padding-top: 2.5rem;
  font-size: 1.2rem;
  padding-bottom: 2.5rem;
}

.faq-question:hover {
  border: 0.1rem solid #0271E3;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  color: #3E4852;
  z-index: 0;
}

.button.accordion-button.faq-question::after {
  color: #3E4852;
}

/* this is for making the arrow black after the item is clicked */
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
  color: #3E4852;
}
