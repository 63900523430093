#intro-card{
    height: 100%;
    border-radius: 2rem;
    /* margin-bottom: 2rem; */
    padding: 4rem;
}

#intro-card-title{
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #3E4852;
}


#intro-card-text{
    font-family: sans-serif;
    font-size: 1.2rem;
    margin: auto;
    color: grey;
}
