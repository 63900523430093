.navbar-nav .nav-item a {
  color: black;
  margin-right: 2rem;
}

.navbar-section {
  border-bottom: 1px solid #e2e2e2;
  position: sticky;
  width: 100%;
  background-color: #F7F7F7;
  top: 0px;
  /* to stop other elemnt to overlay the number, we added z-index */
  z-index: 1;
}

.navbar-section.blurred {
  /* background-color: white; */
  background-color: rgb(255, 255, 255, 0.4);

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* , position: "sticky", width: "100%", backgroundColor: "white", top: "0px", backdropFilter: "blur(10px)" } */
