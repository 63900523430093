#toolCard{
    border-radius: 1.5rem;
    background-color: #efefef;
}

#toolText{
    color: grey;
    font-size: 1.1rem;
    font-family: sans-serif;
    padding-top: 0.5rem;
}