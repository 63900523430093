

#proCard{
    border-radius: 2rem;
    border: 0px;
}


#projectTitle{
    /* padding-top: 2rem;
    color: #43A7A8;
    font-size: 2rem; */

    /* padding-left: 0px;
    background-clip: text;
    margin-left: 1rem;
    font-weight: bold;
    font-size: 2.2rem;
    color: #3E4852;
} */
}

#projectInfo{
    padding-top: 30%;  
    padding-left: 2rem;
    color: white;
    font-size: 1.2rem;
}

#projectImage{
    width: 100%;
    margin-top: 1rem;
    /* padding: 2rem 2rem; */
    border-radius: 1rem;
    height: 28rem;
}

#btnProjects{
    border: 0px;
    font-size: 1rem;
    padding: 0px;
    font-weight: bold;
}

#btnProjects :active{
    border: 0px; 
}