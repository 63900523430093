#midSection{
    background-color: #f7f7f7;
}

#MidSectionCard{
    border-radius: 2rem;
    background-image: linear-gradient(120deg,#088BDD,#0fd2b8);
    border: 0px;
}

#midSectionWord{
    padding-top: 8rem;
    padding-bottom: 8rem;
    font-size: 5rem;
    font-family: sans-serif;
}