#fact-card{
    border-radius: 2rem;
    /* margin-bottom: 2rem; */
    
}


#fact-card-title{
    font-size: 4em;
    font-weight: bolder;
    /* background-image: linear-gradient(#58D0D9, #3DA0D0); because it exists in index.js*/
    background-clip: text;
    color: transparent;
}

#fact-card-subtitle{
    font-size: 1.3em;
    margin-left: .2rem;
    font-weight: bolder;
    /* background-image: linear-gradient(#58D0D9, #3DA0D0); because it exists in index.js*/
    background-clip: text;
    color: transparent;
}

#fact-card-text{
    color: grey;
    font-size: 1.1rem;
    font-family: sans-serif;
}


@media only screen and (max-width: 1199px) {
    #fact-card {
      margin-top: 2.5rem;
    }
  }
