#contactMe {
  padding-top: 3rem;
  font-size: 3rem;
  color: black;
  font-size: 2.5rem;
  font-weight: bold;
  padding-top: 3rem;

  margin-bottom: 3rem;
}

#line {
  margin-left: 40%;
  margin-right: 40%;
  border: 1px solid #3178c6;
}

#btnSend {
  /* background-color: #0271E3;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
  transition: 0.5s; */

  color: white;
  background-color: #0890DB;
  font-size: 1.4rem;
  transition: 0.5s;
  border-radius: 10px;
  padding-left: 3rem;
  padding-right: 3rem;
}

#btnSend:hover {
  /* background-color: black; */
  /* transform: scale(1.3); */
  background-color:#077bb9 ;
    /* text-decoration: underline; */
}

.fluid-gradient-heading-contact {
  background: linear-gradient(
    91.36deg,
    #eca658 0%,
    #f391a6 13.02%,
    #e188c3 25.52%,
    #a58de3 37.5%,
    #56abec 49.48%,
    #737eb7 63.02%,
    #c8638c 72.92%,
    #dd5d57 84.38%,
    #df6c51 97.92%
  );
  background-size: 100% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-animation: intro-gradient 10s infinite ease both;
  -moz-animation: intro-gradient 10s infinite ease both;
  animation: intro-gradient 10s infinite ease both;
  /* animation-duration: 10s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: intro-gradient; */
}

.fluid-gradient-heading-contact {
  /* margin-top: 5rem; */
  margin-bottom: 4rem;
  /* padding-left: 6rem; */
  padding-top: 8px;
  font-weight: bold;
  padding-bottom: 8px;
  font-size: 4rem;
  line-height: 1.1;
  letter-spacing: -0.045em;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
