/* #hero {
  background-image: url("../../img/hero-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 500px;
  width: 100%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */


#home-img {
  box-shadow: 0px 0px 20px #D3D4D6, 0px 0px 20px #D3D4D6;
}

#my-name {
  font-size: 2rem;
  font-family: sans-serif;
  line-height: 1.2;
  font-weight: bold;
  color: #3E4852;
  will-change: transform, opacity;
  animation: fadeIn 2s ease;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-delay: 0.4s;
}

#job-title {
  font-size: 2rem;
  font-weight: bold;
  color: #3E4852;
  font-family: sans-serif;
  will-change: transform, opacity;
  animation: fadeIn 4s ease;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-delay: 0.4s;
}

#info-line {
  font-size: 1.7rem;
  font-family: sans-serif;
  line-height: 1.9;
  color: #949499;
  will-change: transform, opacity;
  animation: fadeIn 6s ease;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-delay: 0.4s;
}

#btnKnowMore {
  /* background-color: black; */
  color: white;
  background-color: #0890DB;
  font-size: 1.4rem;
  transition: 0.5s;
  border-radius: 10px;
  padding-left: 3rem;
  padding-right: 3rem;
}

#btnKnowMore:hover {
  background-color:#077bb9 ;
  /* background-color: black;
  
  transform: scale(1.3); */
  /* text-decoration: underline; */
 
}

/* 
#knowMore {
  position: absolute;
  top: 30%;
  left: 12%;
  height: auto;
} */

/* #knowMore h2 {
  color: #3078c6;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

#knowMore p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
} */
/* 
#btnKnowMore {
  color: white;
  font-size: large;
  background-color: #3078c6;
  border: 0px;
  border-radius: 0px;
} */
/* 
#btnKnowMore:hover {
  background-color: #617d9b;
  border: 0px;
} */

.home-container {
  text-align: center;
  background-color: #F7F7F7;
  /* height: 100vh; */
}
