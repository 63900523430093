.about-section {
  background-color: #f7f7f7;
}


#split-row-four-cards{
  margin-bottom: 2rem;
}
/* to solve the layout problem with different screen sizes */
@media only screen and (min-width: 1200px) {
  #split-row-four-cards {
    margin-bottom: rem;
  }
}
/* to solve the layout problem with different screen sizes */
@media only screen and (max-width: 1399px) {
  #split-row-four-cards {
   margin-bottom: 4rem;
  }
}
/* to solve the layout problem with different screen sizes */
@media only screen and (max-width: 1199px) {
  #split-row-four-cards {
   margin-bottom: 0;
  }
}
