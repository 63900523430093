
#cleanCodeText{
  /* background-image: linear-gradient(#f7b0c7, #f089b4, #b91a5a);  */
   color: #3E4852;
   background-clip: text;
   margin-left: 1rem;
   font-weight: bold;
   font-size: 2.2rem;
   /* color: transparent; */
}


#design {
  background-color: white;
  border-radius: 2rem;
  height: 100%;
  margin-top: 2rem;
  
}

.unique-list {
  margin-left: 1rem;
  margin-top: 2.5rem;
  font-family: sans-serif;
  font-size: 1.5rem;
}

.unique-txt {
  color: #E75893;
  font-family: sans-serif;
  font-size: 1.5rem;
}

#unorder-list {

  /* margin-left: 1rem; */
  height: auto;
  /* padding: 2rem; */
  margin: auto;
 
}


@media only screen and (max-width: 1400px) {
  #unorder-list {
    margin-left: 1rem;
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 992px) {
  #unorder-list {
    margin-left: 1rem;
    margin-top: 5rem;
  }
}

/* style={{ marginLeft: '3rem', marginTop: "5rem", marginBottom: "5rem", top: "50%", left: '50%' }} */
