.footer {
  background-color: black;
  color: white;
  position: relative;
  height: 80px;
}

#upperFooter {
  background-color: #272729;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  padding-top: 30px;
}

#copyright {
  margin: 0;
  top: 50%;
  left: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#githublogo {
  color: white;
}

#twitterlogo {
  color: white;
}

#linkedinlogo {
  color: white;
}

/* #linkedinlogo:hover {
  color: #71a8e3;
}

#githublogo:hover {
  color: #71a8e3;
}

#twitterlogo:hover {
  color: #71a8e3;
} */
