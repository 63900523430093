.fluid-gradient-heading-projects {
    background: linear-gradient(
      91.36deg,
      #eca658 0%,
      #f391a6 13.02%,
      #e188c3 25.52%,
      #a58de3 37.5%,
      #56abec 49.48%,
      #737eb7 63.02%,
      #c8638c 72.92%,
      #dd5d57 84.38%,
      #df6c51 97.92%
    );
    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-animation: intro-gradient 10s infinite ease both;
    -moz-animation: intro-gradient 10s infinite ease both;
    animation: intro-gradient 10s infinite ease both;
    /* animation-duration: 10s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
    animation-name: intro-gradient; */
  }
  
  .fluid-gradient-heading-projects {
    /* margin-top: 5rem; */
    margin-bottom: 4rem;
    padding-left: 2rem;
    padding-top: 8px;
    font-weight: bold;
    padding-bottom: 8px;
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -0.045em;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  #previousProject {
    background-clip: text;
    margin-left: 1rem;
    font-weight: bold;
    font-size: 2.5rem;
    padding-left: 1.5rem;
    color: #3E4852;
    margin-bottom: 3rem;

    /* padding-left: 6rem;
    margin-top: 5rem;
    margin-bottom: 4rem;
    font-size: 4rem;
    color: black;
    font-weight: bold; */
    margin-top: 2rem;
  }