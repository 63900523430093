#name {
  padding-top: 20px;
  font-weight: bold;
}

#description {
  padding-top: 15px;
}

#repositoryIcon {
  color: black;
}

#deployedIcon {
  color: black;
  padding-right: 10px;
}

#repositoryIcon:hover {
  color: black;
}

#deployedIcon:hover {
  color: black;
}

#projectCard:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transform: scale(1.05);
  /* z-index: -1; */
}
