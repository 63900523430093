#DevCard{
    height: 100%;
    border-radius: 2rem;
    /* margin-bottom: 2rem; */
    
    
}

#SketchedWord{
    text-decoration: line-through;
    font-size: 4rem;
    /* color:#afaeae; */
    color: #DC395F;
    font-family: sans-serif;
}

#DisplayedWord{
    font-size: 5.5rem;
     background-image: linear-gradient(#F59D7C, #F59D7C, #C94790 ); 
     background-clip: text;
     color: transparent;
    font-weight: bold;
}

#developmentTime{
    background-clip: text;
    margin-left: 1rem;
    font-weight: bold;
    font-size: 2.2rem;
    color: #3E4852;
    
}