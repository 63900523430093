#mobile-design {
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 2rem;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;

  /* width: auto; */
  /* height: auto; */
  /* overflow: "hidden"; */
}
/* marginLeft: "1rem", fontWeight: "bold", fontSize: "2.2rem" */

#responsiveDesignPictureTitle{
   /* background-image: linear-gradient(#74dff9,#4FBAD4, #3A8CCA);  */
   background-clip: text;
   margin-left: 1rem;
   font-weight: bold;
   font-size: 2.2rem;
   color: #3E4852;
  /* color: transparent; */
}